body {
  background: beige;
  margin: 2%;
}

ul {
  list-style: none;
  padding: 0;
}
ul li {
  margin: 0;
}
ul li:before {
  background: #66f;
  border: 1px double #ddd;
  content: "";
  display: inline-block;
  margin-right: 0.4em;
  height: 0.5em;
  width: 0.5em;
  transform: rotate(45deg);
}

.marquee {
  animation: marquee 10s linear infinite;
}

.navbar {
  margin-right: 10px;
  width: 20%;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-400%);
  }
}
/*# sourceMappingURL=index.d2d99a1f.css.map */
